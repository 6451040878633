import React from 'react';
import { Link } from 'react-router-dom';

const FooterOne = () => {
    return (
        <>
            <footer className="main__footer" style={{ backgroundColor: '#00061a', color: '#fff' }}>
                {/* Horizontal line */}
                <hr style={{ border: '1px solid #47484c', margin: '0 auto', width: '75%' }} />

                <div className="footer-bottom" style={{ padding: '20px 0' }}>
                    <div className="auto-container" style={styles.autoContainer}>
                        <div className="left-section" style={styles.leftSection}>
                            <p style={styles.text}>COPYRIGHT © 2024 SiteGuiders. ALL RIGHTS RESERVED.</p>
                            <div style={styles.linksContainer}>
                                <Link to="/privacy-policy" style={styles.link}>Privacy Policy |</Link>
                                <Link to="/terms-and-conditions" style={styles.link}>Terms and Conditions |</Link>
                                <Link to="/contact" style={styles.link}>Contact Us</Link>
                            </div>
                        </div>
                        <div className="right-section" style={styles.rightSection}>
                            <span style={styles.followUs}>Follow Us:</span>
                            <Link to="/"><i className="icon-10" style={styles.icon}></i></Link>
                            <Link to="/"><i className="icon-14" style={styles.icon}></i></Link>
                            <Link to="/"><i className="icon-01" style={styles.icon}></i></Link>
                            <Link to="/"><i className="icon-11" style={styles.icon}></i></Link>
                        </div>
                    </div>
                </div>
            </footer>

            <style>
                {`
                    @media (max-width: 768px) {
                        .auto-container {
                            flex-direction: column;
                            align-items: flex-start;
                            text-align: left;
                        }

                        .right-section {
                            margin-top: 15px;
                        }
                    }

                    @media (max-width: 480px) {
                        .auto-container {
                            padding: 0 20px;
                        }

                        .right-section {
                            justify-content: flex-start;
                        }

                        .icon {
                            margin-right: 5px;
                        }

                        .follow-us {
                            margin-right: 5px;
                        }
                    }
                `}
            </style>
        </>
    );
};

const styles = {
    autoContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    leftSection: {
        display: 'flex',
        flexDirection: 'column',
    },
    text: {
        margin: 0,
    },
    linksContainer: {
        marginTop: '5px',
    },
    link: {
        color: '#f6ec4f',
        marginRight: '10px',
        textDecoration: 'none',
    },
    rightSection: {
        display: 'flex',
        alignItems: 'center',
    },
    followUs: {
        marginRight: '10px',
    },
    icon: {
        color: '#fff',
        marginRight: '10px',
    },
};

export default FooterOne;
