import React from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import shape4 from "../../../assets/images/resource/portfolio-bg.jpg";
// import two from "../../../assets/images/resource/fitness.png";
import two2 from "../../../assets/images/resource/jdmaxx.jpg";
// import two3 from "../../../assets/images/resource/flowersnow.jpg";
// import two4 from "../../../assets/images/resource/astons.jpg";
import two5 from "../../../assets/images/resource/lollyprop.jpg";
import two6 from "../../../assets/images/resource/ameeneng.jpg";
import two7 from "../../../assets/images/resource/teganlighting.jpg";
import two8 from "../../../assets/images/resource/cliniximagerie.jpg";
import two9 from "../../../assets/images/resource/cateslondon.jpg";
// import two10 from "../../../assets/images/resource/uccbh.png";
// import two11 from "../../../assets/images/resource/immigration.png";
import two12 from "../../../assets/images/resource/goodgamer.jpg";
import two13 from "../../../assets/images/resource/nuguhome.jpg";
import two14 from "../../../assets/images/resource/leevision.jpg";
// import two15 from "../../../assets/images/resource/clearleeperfectaesthetics.png";
// import two16 from "../../../assets/images/resource/patriotstreetfighter.png";
import two17 from "../../../assets/images/resource/zeesy.jpg";
import two18 from "../../../assets/images/resource/khusa.jpg";
// import two19 from "../../../assets/images/resource/metallisresourcess.png";
import two20 from "../../../assets/images/resource/bluegreentraining.jpg";
// import two21 from "../../../assets/images/resource/miningnewsdaily.png";
// import two22 from "../../../assets/images/resource/henleyglobal.png";
// import two23 from "../../../assets/images/resource/microsmallcap.png";
// import two24 from "../../../assets/images/resource/remotejobs.png";
// import two25 from "../../../assets/images/resource/goodgamercorp.png";
// import two26 from "../../../assets/images/resource/listeo.png";
// import two27 from "../../../assets/images/resource/nimbusweb.png";
// import two28 from "../../../assets/images/resource/microcapwatch.png";
// import two29 from "../../../assets/images/resource/althaqafah.png";
// import two30 from "../../../assets/images/resource/kingsofkombucha.png";
// import two31 from "../../../assets/images/resource/zolucky.png";
// import two32 from "../../../assets/images/resource/woofhousepty.png";
// import two33 from "../../../assets/images/resource/sneakerdouble.png";
// import two34 from "../../../assets/images/resource/omnutritionist.png";
// import two35 from "../../../assets/images/resource/straightforwardlistings.png";
// import two36 from "../../../assets/images/resource/citizenshipinvest.png";
// import two37 from "../../../assets/images/resource/montereytouringvehicles.png";
// import two38 from "../../../assets/images/resource/everee.png";
// import two39 from "../../../assets/images/resource/spacesuites.png";
import two40 from "../../../assets/images/resource/richtv.jpg";
import { Link } from 'react-router-dom';

const Portfolio = () => {
  return (
   
    <section className="portfolio  p_relative">
        <div className="portfolio-layer p_absolute" style={{ backgroundImage: `url(${shape4})` }}></div>
        <div className="auto-container">
            <div className="title__data">
                <div className="sub__title">
                    <h4>Projects </h4>
                </div>
                <div className="title">
                    <h2>Some Of Our Projects</h2>
                </div>
            </div>
            <div className="protfolio__inner">
                <OwlCarousel
                className="portfolio-carousel owl-theme owl-carousel owl-dots-none"
                items={4}
                loop={true}
                margin={24}
                smartSpeed={1000}
                autoplay={6000}
                responsive={{
                0: {
                    items: 1,
                },
                600: {
                    items: 2,
                },
                768: {
                    items: 3,
                },
                992: {
                    items: 3,
                },
                1200: {
                    items: 4,
                },
                }}
            >
                <div className="portfolio__block p_relative">
                    <div className="portfolio__image">
                        <figure>
                            <img src={two5} alt="" style={{ height: '467px', width: '410px' }}/>
                        </figure>
                    </div>
                    <div className="lower__content p_absolute">
                        <div className="protfolio__text">
                            <div className="text__block">
                                <h4>Lolly Prop</h4>
                                <p>Property Investment Website</p>
                            </div>
                            <div className="text__block_two">
                                <h5>2023</h5>
                            </div>
                        </div>
                        <div className="protfolio__button">
                            <Link to="/projects" className="theme-btn theme-btn-one"> See More<i className="icon-02"></i></Link>
                        </div>
                    </div>
                </div>
                <div className="portfolio__block p_relative">
                    <div className="portfolio__image">
                        <figure>
                            <img src={two6} alt="" style={{ height: '467px', width: '410px' }}/>
                        </figure>
                    </div>
                    <div className="lower__content p_absolute">
                        <div className="protfolio__text">
                            <div className="text__block">
                                <h4>Ameeneng</h4>
                                <p>Vehicle Rentel Websites</p>
                            </div>
                            <div className="text__block_two">
                                <h5>2022</h5>
                            </div>
                        </div>
                        <div className="protfolio__button">
                            <Link to="/projects" className="theme-btn theme-btn-one"> See More<i className="icon-02"></i></Link>
                        </div>
                    </div>
                </div>
                <div className="portfolio__block p_relative">
                    <div className="portfolio__image">
                        <figure>
                            <img src={two7} alt="" style={{ height: '467px', width: '410px' }}/>
                        </figure>
                    </div>
                    <div className="lower__content p_absolute">
                        <div className="protfolio__text">
                            <div className="text__block">
                                <h4>Tegan Lighting</h4>
                                <p>Home Interior Websites</p>
                            </div>
                            <div className="text__block_two">
                                <h5>2023</h5>
                            </div>
                        </div>
                        <div className="protfolio__button">
                            <Link to="/projects" className="theme-btn theme-btn-one"> See More<i className="icon-02"></i></Link>
                        </div>
                    </div>
                </div>
                <div className="portfolio__block p_relative">
                    <div className="portfolio__image">
                        <figure>
                            <img src={two17} alt="" style={{ height: '467px', width: '410px' }}/>
                        </figure>
                    </div>
                    <div className="lower__content p_absolute">
                        <div className="protfolio__text">
                            <div className="text__block">
                                <h4>Zee Sy</h4>
                                <p>Ecommerce Websites in WordPress</p>
                            </div>
                            <div className="text__block_two">
                                <h5>2022</h5>
                            </div>
                        </div>
                        <div className="protfolio__button">
                            <Link to="/projects" className="theme-btn theme-btn-one"> See More<i className="icon-02"></i></Link>
                        </div>
                    </div>
                </div>
                <div className="portfolio__block p_relative">
                    <div className="portfolio__image">
                        <figure>
                            <img src={two18} alt="" style={{ height: '467px', width: '410px' }}/>
                        </figure>
                    </div>
                    <div className="lower__content p_absolute">
                        <div className="protfolio__text">
                            <div className="text__block">
                                <h4>Khussa</h4>
                                <p>Ecommerce Websites in WordPress</p>
                            </div>
                            <div className="text__block_two">
                                <h5>2024</h5>
                            </div>
                        </div>
                        <div className="protfolio__button">
                            <Link to="/projects" className="theme-btn theme-btn-one"> See More<i className="icon-02"></i></Link>
                        </div>
                    </div>
                </div>
                {/* <div className="portfolio__block p_relative">
                    <div className="portfolio__image">
                        <figure>
                            <img src={two19} alt="" style={{ height: '467px', width: '410px' }}/>
                        </figure>
                    </div>
                    <div className="lower__content p_absolute">
                        <div className="protfolio__text">
                            <div className="text__block">
                                <h4>Metallis Resources</h4>
                                <p>Ecommerce Websites in WordPress</p>
                            </div>
                            <div className="text__block_two">
                                <h5>2022</h5>
                            </div>
                        </div>
                        <div className="protfolio__button">
                            <Link to="/projects" className="theme-btn theme-btn-one"> See More<i className="icon-02"></i></Link>
                        </div>
                    </div>
                </div> */}
                <div className="portfolio__block p_relative">
                    <div className="portfolio__image">
                        <figure>
                            <img src={two20} alt="" style={{ height: '467px', width: '410px' }}/>
                        </figure>
                    </div>
                    <div className="lower__content p_absolute">
                        <div className="protfolio__text">
                            <div className="text__block">
                                <h4>Blue Green Training</h4>
                                <p>Shopify Ecommerce Websites</p>
                            </div>
                            <div className="text__block_two">
                                <h5>2022</h5>
                            </div>
                        </div>
                        <div className="protfolio__button">
                            <Link to="/projects" className="theme-btn theme-btn-one"> See More<i className="icon-02"></i></Link>
                        </div>
                    </div>
                </div>
                {/* <div className="portfolio__block p_relative">
                    <div className="portfolio__image">
                        <figure>
                            <img src={two21} alt="" style={{ height: '467px', width: '410px' }}/>
                        </figure>
                    </div>
                    <div className="lower__content p_absolute">
                        <div className="protfolio__text">
                            <div className="text__block">
                                <h4>Mining News Daily</h4>
                                <p>Ecommerce Websites in WordPress</p>
                            </div>
                            <div className="text__block_two">
                                <h5>2023</h5>
                            </div>
                        </div>
                        <div className="protfolio__button">
                            <Link to="/projects" className="theme-btn theme-btn-one"> See More<i className="icon-02"></i></Link>
                        </div>
                    </div>
                </div> */}
                {/* <div className="portfolio__block p_relative">
                    <div className="portfolio__image">
                        <figure>
                            <img src={two22} alt="" style={{ height: '467px', width: '410px' }}/>
                        </figure>
                    </div>
                    <div className="lower__content p_absolute">
                        <div className="protfolio__text">
                            <div className="text__block">
                                <h4>Henley Partners</h4>
                                <p>Agency Websites</p>
                            </div>
                            <div className="text__block_two">
                                <h5>2022</h5>
                            </div>
                        </div>
                        <div className="protfolio__button">
                            <Link to="/projects" className="theme-btn theme-btn-one"> See More<i className="icon-02"></i></Link>
                        </div>
                    </div>
                </div>
                <div className="portfolio__block p_relative">
                    <div className="portfolio__image">
                        <figure>
                            <img src={two23} alt="" style={{ height: '467px', width: '410px' }}/>
                        </figure>
                    </div>
                    <div className="lower__content p_absolute">
                        <div className="protfolio__text">
                            <div className="text__block">
                                <h4>Micro Small Cap</h4>
                                <p>Stocks Websites</p>
                            </div>
                            <div className="text__block_two">
                                <h5>2023</h5>
                            </div>
                        </div>
                        <div className="protfolio__button">
                            <Link to="/projects" className="theme-btn theme-btn-one"> See More<i className="icon-02"></i></Link>
                        </div>
                    </div>
                </div> */}
                {/* <div className="portfolio__block p_relative">
                    <div className="portfolio__image">
                        <figure>
                            <img src={two24} alt="" style={{ height: '467px', width: '410px' }}/>
                        </figure>
                    </div>
                    <div className="lower__content p_absolute">
                        <div className="protfolio__text">
                            <div className="text__block">
                                <h4>Remote Jobs</h4>
                                <p>Jobs Websites</p>
                            </div>
                            <div className="text__block_two">
                                <h5>2022</h5>
                            </div>
                        </div>
                        <div className="protfolio__button">
                            <Link to="/projects" className="theme-btn theme-btn-one"> See More<i className="icon-02"></i></Link>
                        </div>
                    </div>
                </div> */}
                {/* <div className="portfolio__block p_relative">
                    <div className="portfolio__image">
                        <figure>
                            <img src={two25} alt="" style={{ height: '467px', width: '410px' }}/>
                        </figure>
                    </div>
                    <div className="lower__content p_absolute">
                        <div className="protfolio__text">
                            <div className="text__block">
                                <h4>Good Gamer Corp</h4>
                                <p>Ecommerce Websites in WordPress</p>
                            </div>
                            <div className="text__block_two">
                                <h5>2023</h5>
                            </div>
                        </div>
                        <div className="protfolio__button">
                            <Link to="/projects" className="theme-btn theme-btn-one"> See More<i className="icon-02"></i></Link>
                        </div>
                    </div>
                </div> */}
                {/* <div className="portfolio__block p_relative">
                    <div className="portfolio__image">
                        <figure>
                            <img src={two26} alt="" style={{ height: '467px', width: '410px' }}/>
                        </figure>
                    </div>
                    <div className="lower__content p_absolute">
                        <div className="protfolio__text">
                            <div className="text__block">
                                <h4>Listeo</h4>
                                <p>Ecommerce Websites in WordPress</p>
                            </div>
                            <div className="text__block_two">
                                <h5>2024</h5>
                            </div>
                        </div>
                        <div className="protfolio__button">
                            <Link to="/projects" className="theme-btn theme-btn-one"> See More<i className="icon-02"></i></Link>
                        </div>
                    </div>
                </div> */}
                {/* <div className="portfolio__block p_relative">
                    <div className="portfolio__image">
                        <figure>
                            <img src={two27} alt="" style={{ height: '467px', width: '410px' }}/>
                        </figure>
                    </div>
                    <div className="lower__content p_absolute">
                        <div className="protfolio__text">
                            <div className="text__block">
                                <h4>Nimbusweb</h4>
                                <p>Ecommerce Websites in WordPress</p>
                            </div>
                            <div className="text__block_two">
                                <h5>2023</h5>
                            </div>
                        </div>
                        <div className="protfolio__button">
                            <Link to="/projects" className="theme-btn theme-btn-one"> See More<i className="icon-02"></i></Link>
                        </div>
                    </div>
                </div> */}
                {/* <div className="portfolio__block p_relative">
                    <div className="portfolio__image">
                        <figure>
                            <img src={two28} alt="" style={{ height: '467px', width: '410px' }}/>
                        </figure>
                    </div>
                    <div className="lower__content p_absolute">
                        <div className="protfolio__text">
                            <div className="text__block">
                                <h4>Microcap Watch</h4>
                                <p>Ecommerce Websites in WordPress</p>
                            </div>
                            <div className="text__block_two">
                                <h5>2023</h5>
                            </div>
                        </div>
                        <div className="protfolio__button">
                            <Link to="/projects" className="theme-btn theme-btn-one"> See More<i className="icon-02"></i></Link>
                        </div>
                    </div>
                </div> */}
                {/* <div className="portfolio__block p_relative">
                    <div className="portfolio__image">
                        <figure>
                            <img src={two29} alt="" style={{ height: '467px', width: '410px' }}/>
                        </figure>
                    </div>
                    <div className="lower__content p_absolute">
                        <div className="protfolio__text">
                            <div className="text__block">
                                <h4>Al-Thaqafah</h4>
                                <p>Ecommerce Websites in WordPress</p>
                            </div>
                            <div className="text__block_two">
                                <h5>2022</h5>
                            </div>
                        </div>
                        <div className="protfolio__button">
                            <Link to="/projects" className="theme-btn theme-btn-one"> See More<i className="icon-02"></i></Link>
                        </div>
                    </div>
                </div> */}
                {/* <div className="portfolio__block p_relative">
                    <div className="portfolio__image">
                        <figure>
                            <img src={two30} alt="" style={{ height: '467px', width: '410px' }}/>
                        </figure>
                    </div>
                    <div className="lower__content p_absolute">
                        <div className="protfolio__text">
                            <div className="text__block">
                                <h4>Kings Of Kombucha</h4>
                                <p>Ecommerce Websites in WordPress</p>
                            </div>
                            <div className="text__block_two">
                                <h5>2023</h5>
                            </div>
                        </div>
                        <div className="protfolio__button">
                            <Link to="/projects" className="theme-btn theme-btn-one"> See More<i className="icon-02"></i></Link>
                        </div>
                    </div>
                </div> */}
                {/* <div className="portfolio__block p_relative">
                    <div className="portfolio__image">
                        <figure>
                            <img src={two31} alt="" style={{ height: '467px', width: '410px' }}/>
                        </figure>
                    </div>
                    <div className="lower__content p_absolute">
                        <div className="protfolio__text">
                            <div className="text__block">
                                <h4>Zolucky</h4>
                                <p>Ecommerce Websites in WordPress</p>
                            </div>
                            <div className="text__block_two">
                                <h5>2023</h5>
                            </div>
                        </div>
                        <div className="protfolio__button">
                            <Link to="/projects" className="theme-btn theme-btn-one"> See More<i className="icon-02"></i></Link>
                        </div>
                    </div>
                </div> */}
                {/* <div className="portfolio__block p_relative">
                    <div className="portfolio__image">
                        <figure>
                            <img src={two32} alt="" style={{ height: '467px', width: '410px' }}/>
                        </figure>
                    </div>
                    <div className="lower__content p_absolute">
                        <div className="protfolio__text">
                            <div className="text__block">
                                <h4>Woof House</h4>
                                <p>Ecommerce Websites in WordPress</p>
                            </div>
                            <div className="text__block_two">
                                <h5>2024</h5>
                            </div>
                        </div>
                        <div className="protfolio__button">
                            <Link to="/projects" className="theme-btn theme-btn-one"> See More<i className="icon-02"></i></Link>
                        </div>
                    </div>
                </div> */}
                {/* <div className="portfolio__block p_relative">
                    <div className="portfolio__image">
                        <figure>
                            <img src={two33} alt="" style={{ height: '467px', width: '410px' }}/>
                        </figure>
                    </div>
                    <div className="lower__content p_absolute">
                        <div className="protfolio__text">
                            <div className="text__block">
                                <h4>Sneaker Double</h4>
                                <p>Ecommerce Websites in WordPress</p>
                            </div>
                            <div className="text__block_two">
                                <h5>2022</h5>
                            </div>
                        </div>
                        <div className="protfolio__button">
                            <Link to="/projects" className="theme-btn theme-btn-one"> See More<i className="icon-02"></i></Link>
                        </div>
                    </div>
                </div> */}
                {/* <div className="portfolio__block p_relative">
                    <div className="portfolio__image">
                        <figure>
                            <img src={two34} alt="" style={{ height: '467px', width: '410px' }}/>
                        </figure>
                    </div>
                    <div className="lower__content p_absolute">
                        <div className="protfolio__text">
                            <div className="text__block">
                                <h4>Om Nutritionist</h4>
                                <p>Doctors WordPress Websites</p>
                            </div>
                            <div className="text__block_two">
                                <h5>2022</h5>
                            </div>
                        </div>
                        <div className="protfolio__button">
                            <Link to="/projects" className="theme-btn theme-btn-one"> See More<i className="icon-02"></i></Link>
                        </div>
                    </div>
                </div>
                <div className="portfolio__block p_relative">
                    <div className="portfolio__image">
                        <figure>
                            <img src={two35} alt="" style={{ height: '467px', width: '410px' }}/>
                        </figure>
                    </div>
                    <div className="lower__content p_absolute">
                        <div className="protfolio__text">
                            <div className="text__block">
                                <h4>Straight Forward Listings</h4>
                                <p>Property Investment Website</p>
                            </div>
                            <div className="text__block_two">
                                <h5>2022</h5>
                            </div>
                        </div>
                        <div className="protfolio__button">
                            <Link to="/projects" className="theme-btn theme-btn-one"> See More<i className="icon-02"></i></Link>
                        </div>
                    </div>
                </div>
                <div className="portfolio__block p_relative">
                    <div className="portfolio__image">
                        <figure>
                            <img src={two36} alt="" style={{ height: '467px', width: '410px' }}/>
                        </figure>
                    </div>
                    <div className="lower__content p_absolute">
                        <div className="protfolio__text">
                            <div className="text__block">
                                <h4>Citizen Shipinvest</h4>
                                <p>Property Investment Website</p>
                            </div>
                            <div className="text__block_two">
                                <h5>2023</h5>
                            </div>
                        </div>
                        <div className="protfolio__button">
                            <Link to="/projects" className="theme-btn theme-btn-one"> See More<i className="icon-02"></i></Link>
                        </div>
                    </div>
                </div>
                <div className="portfolio__block p_relative">
                    <div className="portfolio__image">
                        <figure>
                            <img src={two37} alt="" style={{ height: '467px', width: '410px' }}/>
                        </figure>
                    </div>
                    <div className="lower__content p_absolute">
                        <div className="protfolio__text">
                            <div className="text__block">
                                <h4>Monterey touring vehicles</h4>
                                <p>Vehicle Rentel Websites</p>
                            </div>
                            <div className="text__block_two">
                                <h5>2023</h5>
                            </div>
                        </div>
                        <div className="protfolio__button">
                            <Link to="/projects" className="theme-btn theme-btn-one"> See More<i className="icon-02"></i></Link>
                        </div>
                    </div>
                </div>
                <div className="portfolio__block p_relative">
                    <div className="portfolio__image">
                        <figure>
                            <img src={two38} alt="" style={{ height: '467px', width: '410px' }}/>
                        </figure>
                    </div>
                    <div className="lower__content p_absolute">
                        <div className="protfolio__text">
                            <div className="text__block">
                                <h4>Everee</h4>
                                <p>Payroll Website</p>
                            </div>
                            <div className="text__block_two">
                                <h5>2023</h5>
                            </div>
                        </div>
                        <div className="protfolio__button">
                            <Link to="/projects" className="theme-btn theme-btn-one"> See More<i className="icon-02"></i></Link>
                        </div>
                    </div>
                </div>
                <div className="portfolio__block p_relative">
                    <div className="portfolio__image">
                        <figure>
                            <img src={two39} alt="" style={{ height: '467px', width: '410px' }}/>
                        </figure>
                    </div>
                    <div className="lower__content p_absolute">
                        <div className="protfolio__text">
                            <div className="text__block">
                                <h4>Space Suites</h4>
                                <p>Hotel Booking Websites</p>
                            </div>
                            <div className="text__block_two">
                                <h5>2023</h5>
                            </div>
                        </div>
                        <div className="protfolio__button">
                            <Link to="/projects" className="theme-btn theme-btn-one"> See More<i className="icon-02"></i></Link>
                        </div>
                    </div>
                </div> */}
                <div className="portfolio__block p_relative">
                    <div className="portfolio__image">
                        <figure>
                            <img src={two40} alt="" style={{ height: '467px', width: '410px' }}/>
                        </figure>
                    </div>
                    <div className="lower__content p_absolute">
                        <div className="protfolio__text">
                            <div className="text__block">
                                <h4>Rich TV</h4>
                                <p>Stocks Websites</p>
                            </div>
                            <div className="text__block_two">
                                <h5>2023</h5>
                            </div>
                        </div>
                        <div className="protfolio__button">
                            <Link to="/projects" className="theme-btn theme-btn-one"> See More<i className="icon-02"></i></Link>
                        </div>
                    </div>
                </div>
                <div className="portfolio__block p_relative">
                    <div className="portfolio__image">
                        <figure>
                            <img src={two8} alt="" style={{ height: '467px', width: '410px' }}/>
                        </figure>
                    </div>
                    <div className="lower__content p_absolute">
                        <div className="protfolio__text">
                            <div className="text__block">
                                <h4>Clinix Imagerie</h4>
                                <p>Doctors WordPress Websites</p>
                            </div>
                            <div className="text__block_two">
                                <h5>2023</h5>
                            </div>
                        </div>
                        <div className="protfolio__button">
                            <Link to="/projects" className="theme-btn theme-btn-one"> See More<i className="icon-02"></i></Link>
                        </div>
                    </div>
                </div>
                <div className="portfolio__block p_relative">
                    <div className="portfolio__image">
                        <figure>
                            <img src={two9} alt="" style={{ height: '467px', width: '410px' }}/>
                        </figure>
                    </div>
                    <div className="lower__content p_absolute">
                        <div className="protfolio__text">
                            <div className="text__block">
                                <h4>Cates London</h4>
                                <p>Property Investment Website</p>
                            </div>
                            <div className="text__block_two">
                                <h5>2023</h5>
                            </div>
                        </div>
                        <div className="protfolio__button">
                            <Link to="/projects" className="theme-btn theme-btn-one"> See More<i className="icon-02"></i></Link>
                        </div>
                    </div>
                </div>
                {/* <div className="portfolio__block p_relative">
                    <div className="portfolio__image">
                        <figure>
                            <img src={two10} alt="" style={{ height: '467px', width: '410px' }}/>
                        </figure>
                    </div>
                    <div className="lower__content p_absolute">
                        <div className="protfolio__text">
                            <div className="text__block">
                                <h4>Ucc Bh</h4>
                                <p>Food Hotel Websites</p>
                            </div>
                            <div className="text__block_two">
                                <h5>2023</h5>
                            </div>
                        </div>
                        <div className="protfolio__button">
                            <Link to="/projects" className="theme-btn theme-btn-one"> See More<i className="icon-02"></i></Link>
                        </div>
                    </div>
                </div> */}
                <div className="portfolio__block p_relative">
                    <div className="portfolio__image">
                        <figure>
                            <img src={two12} alt="" style={{ height: '467px', width: '410px' }}/>
                        </figure>
                    </div>
                    <div className="lower__content p_absolute">
                        <div className="protfolio__text">
                            <div className="text__block">
                                <h4>Good Goodamer</h4>
                                <p>Gaming Website</p>
                            </div>
                            <div className="text__block_two">
                                <h5>2023</h5>
                            </div>
                        </div>
                        <div className="protfolio__button">
                            <Link to="/projects" className="theme-btn theme-btn-one"> See More<i className="icon-02"></i></Link>
                        </div>
                    </div>
                </div>
                <div className="portfolio__block p_relative">
                    <div className="portfolio__image">
                        <figure>
                            <img src={two13} alt="" style={{ height: '467px', width: '410px' }}/>
                        </figure>
                    </div>
                    <div className="lower__content p_absolute">
                        <div className="protfolio__text">
                            <div className="text__block">
                                <h4>Nugu Home</h4>
                                <p>Shopify Ecommerce Websites</p>
                            </div>
                            <div className="text__block_two">
                                <h5>2023</h5>
                            </div>
                        </div>
                        <div className="protfolio__button">
                            <Link to="/projects" className="theme-btn theme-btn-one"> See More<i className="icon-02"></i></Link>
                        </div>
                    </div>
                </div>
                {/* <div className="portfolio__block p_relative">
                    <div className="portfolio__image">
                        <figure>
                            <img src={two16} alt="" style={{ height: '467px', width: '410px' }}/>
                        </figure>
                    </div>
                    <div className="lower__content p_absolute">
                        <div className="protfolio__text">
                            <div className="text__block">
                                <h4>Patriot Street Fighter</h4>
                                <p>Ecommerce Websites in WordPress</p>
                            </div>
                            <div className="text__block_two">
                                <h5>2023</h5>
                            </div>
                        </div>
                        <div className="protfolio__button">
                            <Link to="/projects" className="theme-btn theme-btn-one"> See More<i className="icon-02"></i></Link>
                        </div>
                    </div>
                </div>
                <div className="portfolio__block p_relative">
                    <div className="portfolio__image">
                        <figure>
                            <img src={two11} alt="" style={{ height: '467px', width: '410px' }}/>
                        </figure>
                    </div>
                    <div className="lower__content p_absolute">
                        <div className="protfolio__text">
                            <div className="text__block">
                                <h4>Immigration Advice</h4>
                                <p>Consultant Websites</p>
                            </div>
                            <div className="text__block_two">
                                <h5>2022</h5>
                            </div>
                        </div>
                        <div className="protfolio__button">
                            <Link to="/projects" className="theme-btn theme-btn-one"> See More<i className="icon-02"></i></Link>
                        </div>
                    </div>
                </div> */}
                <div className="portfolio__block p_relative">
                    <div className="portfolio__image">
                        <figure>
                            <img src={two14} alt="" style={{ height: '467px', width: '410px' }}/>
                        </figure>
                    </div>
                    <div className="lower__content p_absolute">
                        <div className="protfolio__text">
                            <div className="text__block">
                                <h4>Leevision</h4>
                                <p>Doctors WordPress Websites</p>
                            </div>
                            <div className="text__block_two">
                                <h5>2024</h5>
                            </div>
                        </div>
                        <div className="protfolio__button">
                            <Link to="/projects" className="theme-btn theme-btn-one"> See More<i className="icon-02"></i></Link>
                        </div>
                    </div>
                </div>
                {/* <div className="portfolio__block p_relative">
                    <div className="portfolio__image">
                        <figure>
                            <img src={two15} alt="" style={{ height: '467px', width: '410px' }}/>
                        </figure>
                    </div>
                    <div className="lower__content p_absolute">
                        <div className="protfolio__text">
                            <div className="text__block">
                                <h4>Clearlee Perfect Aesthetics</h4>
                                <p>Doctors WordPress Websites</p>
                            </div>
                            <div className="text__block_two">
                                <h5>2024</h5>
                            </div>
                        </div>
                        <div className="protfolio__button">
                            <Link to="/projects" className="theme-btn theme-btn-one"> See More<i className="icon-02"></i></Link>
                        </div>
                    </div>
                </div> */}
                {/* <div className="portfolio__block p_relative">
                    <div className="portfolio__image">
                        <figure>
                            <img src={two} alt="" style={{ height: '467px', width: '410px' }}/>
                        </figure>
                    </div>
                    <div className="lower__content p_absolute">
                        <div className="protfolio__text">
                            <div className="text__block">
                                <h4>Set For Set</h4>
                                <p>Shopify E-Commerce Website</p>
                            </div>
                            <div className="text__block_two">
                                <h5>2023</h5>
                            </div>
                        </div>
                        <div className="protfolio__button">
                            <Link to="/projects" className="theme-btn theme-btn-one"> See More<i className="icon-02"></i></Link>
                        </div>
                    </div>
                </div> */}
                <div className="portfolio__block p_relative">
                    <div className="portfolio__image">
                        <figure>
                        <img src={two2} alt="" style={{ height: '467px', width: '410px' }}/>
                        </figure>
                    </div>
                    <div className="lower__content p_absolute">
                        <div className="protfolio__text">
                            <div className="text__block">
                                <h4>JDMAXX</h4>
                                <p>Wordpress E-Commerce Website</p>
                            </div>
                            <div className="text__block_two">
                                <h5>2024</h5>
                            </div>
                        </div>
                        <div className="protfolio__button">
                            <Link to="/projects" className="theme-btn theme-btn-one"> See More<i className="icon-02"></i></Link>
                        </div>
                    </div>
                </div>
                {/* <div className="portfolio__block p_relative">
                    <div className="portfolio__image">
                        <figure>
                            <img src={two3} alt="" />
                        </figure>
                    </div>
                    <div className="lower__content p_absolute">
                        <div className="protfolio__text">
                            <div className="text__block">
                                <h4>Flowersnow</h4>
                                <p>Wordpress E-commerce Website</p>
                            </div>
                            <div className="text__block_two">
                                <h5>2023</h5>
                            </div>
                        </div>
                        <div className="protfolio__button">
                            <Link to="/projects" className="theme-btn theme-btn-one"> See More<i className="icon-02"></i></Link>
                        </div>
                    </div>
                </div> */}
                {/* <div className="portfolio__block p_relative">
                    <div className="portfolio__image">
                        <figure>
                            <img src={two4} alt="" />
                        </figure>
                    </div>
                    <div className="lower__content p_absolute">
                        <div className="protfolio__text">
                            <div className="text__block">
                                <h4>Astons</h4>
                                <p>Property Invetment Website</p>
                            </div>
                            <div className="text__block_two">
                                <h5>2023</h5>
                            </div>
                        </div>
                        <div className="protfolio__button">
                            <Link to="/projects" className="theme-btn theme-btn-one"> See More<i className="icon-02"></i></Link>
                        </div>
                    </div>
                </div> */}
                </OwlCarousel>
            </div>
        </div>
    </section>
  );
};

export default Portfolio;
