import React, { useState } from 'react';
import { pageTitle } from '../PageTitle';
// import BreadCrumb  from '../BreadCrumb';
import HeaderOne from '../Header/HeaderOne';
import FooterOne from '../Footer/FooterOne';
import { Link } from 'react-router-dom';
import two from "../../assets/images/resource/man.png";
// import service from "../../assets/images/resource/service-details.jpg";
import basebg from "../../assets/images/resource/base.png";
import webdevelopment from "../../assets/images/resource/web-developpment.jpg";
// Import FontAwesome icons
// import '@fortawesome/fontawesome-free/css/all.min.css';

const Webdevelopment = () => {
    pageTitle('Web Development');
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        subject: '',
        phone: '',
        message: ''
    });

    const handleSubmit = (e) => {
        e.preventDefault();

        console.log('Form submitted:', formData);

        setFormData({
            name: '',
            email: '',
            subject: '',
            phone: '',
            message: ''
        });
    };

    const servicesData = [
        { id: 1, title: 'Web Development', slug: '/web-development' },
        { id: 2, title: 'Digital Marketing', slug: '/digital-marketing' },
        { id: 3, title: 'SEO Marketing', slug: '/seo-marketing' },
        { id: 4, title: 'Graphic Designing', slug: '/graphic-designing' },
    ];

    const renderServices = () => {
        return servicesData.map((service) => (
            <li key={service.id} className={service.slug === '/web-development' ? 'active' : ''}>
                <Link to={service.slug}>{service.title}</Link>
            </li>
        ));
    };

    return (
        <>
            <HeaderOne />
            {/* <BreadCrumb /> */}
            <div className="service__details p_relative ">
                <div className="auto-container">
                    <div className="row">
                        <div className="col-xl-4 col-lg-6 col-md-12 hiding-image">
                            <div className="service__details__left">
                                <div className="sidebar__widget">
                                    <div className="all__service">
                                        <h4>All Services</h4>
                                    </div>
                                    <ul className="service__details__1__cat">
                                        {renderServices()}
                                    </ul>
                                </div>
                                <div className=" sidebar__widget_network" style={{ backgroundImage: `url(${basebg})` }}>                            
                                    <h3>Contact Us For Any Requirement</h3>
                                    <div className="services__icon">
                                        <i className="icon-20"></i>
                                    </div>
                                    <p>Need help? Email us:</p>
                                    <h4><a href="mailto:info@siteguiders.com">info@siteguiders.com</a></h4>
                                </div> 
                            </div>
                        </div>
                        <div className="col-xl-8 col-lg-12 col-md-12">
                            <div className="service__right">
                                <div>
                                    <h2 style={{ marginBottom: '20px' }}>Web Development</h2>
                                </div>
                                <figure>
                                    <img src={webdevelopment} alt="Web Development" />
                                </figure>
                                <h2>Custom Web Development Solutions for Your <span>Business.</span></h2>
                                <p>At our Web Development service, we focus on building websites that are not only visually appealing but also highly functional. Whether you need a simple landing page, an e-commerce site, or a complex web application, our team of experienced developers is equipped to bring your vision to life.</p>
                                <p>Our approach is centered around understanding your business needs and tailoring our solutions accordingly. We specialize in creating responsive, user-friendly websites that provide an excellent user experience on all devices. From front-end design to back-end development, we ensure that every aspect of your website is optimized for performance and scalability.</p>
                                <div className="row">
                                    <div className="col-lg-6 col-md-12">
                                        <li> <Link to="#"><i className="icon-04"></i> Custom Web Development</Link></li>
                                    </div>
                                    <div className="col-lg-6 col-md-12">
                                        <li> <Link to="#"><i className="icon-04"></i> E-commerce Solutions</Link></li>
                                    </div>
                                    <div className="col-lg-6 col-md-12">
                                        <li> <Link to="#"><i className="icon-04"></i> Content Management Systems</Link></li>
                                    </div>
                                    <div className="col-lg-6 col-md-12">
                                        <li> <Link to="#"><i className="icon-04"></i> Web Application Development</Link></li>
                                    </div>
                                </div>
                                <p>Our commitment to quality and client satisfaction sets us apart. We work closely with you throughout the development process, ensuring that the final product meets your expectations and helps your business succeed online.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="service__contact see__pad p_relative">
                <div className="auto-container">
                    <div className="service__contact__block">
                        <div className="row">
                            <div className="col-lg-6 col-md-12">
                                <div className="service__form__data">
                                    <div className="form__title">
                                        <div className="title__data">
                                            <div className="sub__title">
                                                <h4>Contact Us</h4>
                                            </div>
                                            <div className="title">
                                                <h2>Send a Message</h2>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-inner">
                                        <form onSubmit={handleSubmit} method="post" action="contact.php" className="default-form"> 
                                            <div className="row clearfix">
                                                <div className="col-xl-6 form-group">
                                                    <input type="name" name="name" placeholder="Your Name *" required />
                                                </div>
                                                <div className="col-xl-6 col-lg-12 form-group">
                                                    <input type="email" name="email" placeholder="Your Email *" required />
                                                </div>
                                                <div className="col-xl-6 col-lg-12 form-group">
                                                    <input type="text" name="subject" placeholder="Your Subject" required />
                                                </div>
                                                <div className="col-xl-6 col-lg-12 form-group">
                                                    <input type="text" name="phone" placeholder="Your Phone" required />
                                                </div>
                                                <div className="col-xl-12 col-lg-12 form-group">
                                                    <textarea name="message" placeholder="Message"></textarea>
                                                </div>
                                                <div className="col-xl-12 form-group message-btn btn-box">
                                                    <button className="theme-btn theme-btn-one" type="submit" name="submit-form">Send Your Message</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-12 p_relative hiding-image">
                                <div className="service__contact__img">
                                    <figure>
                                        <img src={two} alt="Contact Us" />
                                    </figure>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Add icons at the bottom */}
            <div className="icon__list p_relative">
                <div className="auto-container">
                    <div className="icon__items">
                        <i className="fab fa-html5 fa-3x" title="HTML"></i>
                        <i className="fab fa-css3-alt fa-3x" title="CSS"></i>
                        <i className="fab fa-js-square fa-3x" title="JavaScript"></i>
                        <i className="fab fa-php fa-3x" title="PHP"></i>
                        <i className="fab fa-wordpress fa-3x" title="WordPress"></i>
                        <i className="fab fa-shopify fa-3x" title="Shopify"></i>
                        <i className="fab fa-webflow fa-3x" title="Webflow"></i>
                        <i className="fab fa-wix fa-3x" title="Wix"></i>
                    </div>
                </div>
            </div>
            <FooterOne />
        </>
    );
};

export default Webdevelopment;
